import React, { useEffect } from "react";
import gsap from "gsap";

const FadeInAnimations: React.FC = () => {
    useEffect(() => {
        const fadeInElements = document.querySelectorAll(".fade-in-component");

        const fadeIn = (element: Element) => {
            gsap.fromTo(
                element,
                { opacity: 0, y: 32 },
                { opacity: 1, y: 0, duration: 1, ease: "power2.out" }
            );
            // Add class fade-in-done to prevent re-triggering
            element.classList.add("fade-in-done");
        };

        const observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    if (
                        entry.isIntersecting &&
                        !entry.target.classList.contains("fade-in-done")
                    ) {
                        fadeIn(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );

        fadeInElements.forEach(element => {
            observer.observe(element);
        });

        return () => {
            observer.disconnect();
        };
    }, []);

    return null;
};

export default FadeInAnimations;
